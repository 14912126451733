<template>
  <div class="block-empty">Место для рекламного макета</div>
</template>

<script>
export default {
  name: "t-block-empty",
};
</script>

<style>
.block-empty {
  width: 100%;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  color: #717171;
}
</style>
