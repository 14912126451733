<template>
  <div class="keyboard-block popupKeyboad" @click="$emit('isPopupKeyboad')">
    <div class="keyboard" @click.stop>
      <div class="keyboard__block">
        <div class="keyboard__container _container">
          <div class="keyboard__body">
            <div class="keyboard__swap"></div>
            <div class="keyboard__input-promo">
              <div class="keyboard__input-block">
                <input
                  type="text"
                  name="promo"
                  placeholder="Введите промокод"
                  v-model="promocodeInput"
                  @input="promocodeLetter"
                />
              </div>
              <button class="applyPromocode btn-lightgreen" @click="$emit('applyPromocode', promocodeInput)">
                Применить
              </button>
            </div>
            <div class="keyboard__keys" @click="promocodeLetter">
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="(number, index) in 9"
                  :key="index"
                >
                  {{ number }}
                </button>
                <button class="keyboard__key key">0</button>
                <button class="btn btn-lightgreen btn-long key">
                  {{ $t("cart.btn_clear") }}
                </button>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in letters1"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <button class="btn btn-lightgreen btn-long2 key">
                  <img
                    src="@/assets/img/svg/clear.svg"
                    alt="clear"
                    class="clear"
                  />
                </button>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in letters2"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <button class="btn btn-lightgreen btn-long2 key">
                  <img
                    src="@/assets/img/svg/space.svg"
                    alt="space"
                    class="space"
                  />
                </button>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in letters3"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <button class="btn btn-lightgreen btn-long key">ABC</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "t-popup-promocode",
  data() {
    return {
      promocodeInput: "",
      promocodeValidation: {
        promocode: null,
      },
      letters1: [
        { id: 1, char: `й` },
        { id: 2, char: `ц` },
        { id: 3, char: `у` },
        { id: 4, char: `к` },
        { id: 5, char: `е` },
        { id: 6, char: `н` },
        { id: 7, char: `г` },
        { id: 8, char: `ш` },
        { id: 9, char: `щ` },
        { id: 10, char: `з` },
        { id: 11, char: `х` },
        { id: 12, char: `ъ` },
      ],
      letters2: [
        { id: 1, char: `ф` },
        { id: 2, char: `ы` },
        { id: 3, char: `в` },
        { id: 4, char: `а` },
        { id: 5, char: `п` },
        { id: 6, char: `р` },
        { id: 7, char: `о` },
        { id: 8, char: `л` },
        { id: 9, char: `д` },
        { id: 10, char: `ж` },
        { id: 11, char: `э` },
      ],
      letters3: [
        { id: 1, char: `я` },
        { id: 2, char: `ч` },
        { id: 3, char: `с` },
        { id: 4, char: `м` },
        { id: 5, char: `и` },
        { id: 6, char: `т` },
        { id: 7, char: `ь` },
        { id: 8, char: `б` },
        { id: 9, char: `ю` },
        { id: 10, char: `_` },
      ],
    };
  },
  props: ["promocode"],
  methods: {
    promocodeLetter() {
      if (event.target.className !== "keyboard__row") {
        let key = event.target.innerHTML || event.target.className;
        if (key === "clear")
          this.promocodeInput = this.promocodeInput.substr(
            0,
            this.promocodeInput.length - 1
          );
        else if (key === "Очистить") this.promocodeInput = "";
        else if (key === "space") this.promocodeInput += " ";
        else this.promocodeInput += key.toUpperCase();
      }
      this.promocodeValidation.promocodeInput = this.promocode ? true : false;
    },
  },
};
</script>
