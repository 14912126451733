<template>
  <div class="payment">
    <div class="payment__container _container">
      <div class="payment__body">
        <h2 class="payment__title" v-if="onProccess">Ожидаем оплаты...</h2>
        <div v-if="onProccess" class="onProccess">
          <div class="loads">
            <img src="@/assets/img/svg/flower.svg" alt="loads" />
          </div>
          <div class="payment__flex-col">
            <h2 class="payment__subtitle">
              <br />
              {{ $t("payment.subtitle") }} {{ $t("payment.subtitle1") }}<br />
              на {{ $t("payment.subtitle3") }}
            </h2>
            <div class="payment__arrow">
              <img src="@/assets/img/svg/arrow-down.svg" alt="arrow" />
            </div>
          </div>
        </div>
        <div v-if="!onProccess && !paymentError" class="order_number">
          <h3 class="payment__subtitle">
            <br />
            <div v-if="!orderObj.order_number">
              {{ $t("payment.subtitle_err") }}<br />
              {{ $t("payment.subtitle_err1") }}<br />
              {{ $t("payment.subtitle_err2") }}<br />
              {{ $t("payment.subtitle_err3") }}<br />
            </div>
            >
          </h3>
        </div>
        <!-- <div class="give-check" v-if="orderObj.order_number">
          <p>{{ $t("payment.give_check") }}</p>
          <img src="@/assets/img/svg/down.svg" alt="arrow" width="100" />
        </div> -->
        <div v-if="!onProccess && paymentError">
          <h2 class="payment__subtitle">
            <br />
            {{ $t("payment.pay_err") }}<br />
            {{ $t("payment.pay_err2") }}<br />
            {{ $t("payment.pay_err3") }}<br />
            <a
              @click="this.$router.go(this.$router.currentRoute)"
              class="btn btn-beige to-catalog"
              >{{ $t("payment.repeat") }}</a
            >
            <a
              @click="this.$router.push('/')"
              class="btn btn-beige to-catalog"
              >{{ $t("payment.cancel") }}</a
            >
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-payment",
  data() {
    return {
      onProccess: true,
      paymentError: true,
      orderObj: {},
      orderId: localStorage.getItem("orderId"),
    };
  },
  mounted() {
    if (!this.orderId) {
      this.$router.push("/");
    }

    let self = this;
    axios.get(api + `orders/` + this.orderId + "/").then(function (response) {
      self.orderObj = response.data;
      axios
        .get(
          "http://127.0.0.1:5000/payment/create/?amount=" +
            self.orderObj.full_price * 100
        )
        .then((response) => {
          self.onProccess = false;
          if (response.data.success) {
            self.paymentError = false;
            axios
              .post(api + `order-confirm/`, { order_id: self.orderId })
              .then((response) => {
                self.onProccess = false;
                self.orderObj = response.data;

                self.$router.push("/finish/");
              });
          }
        })
        .catch(() => {
          self.paymentError = true;
          self.onProccess = false;
        });
    });
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/payment.scss";
@import "~@/assets/scss/finish.scss";
</style>
