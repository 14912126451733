<template>
  <header
    class="header"
    :style="img ? `min-height:${imgMinHeight}px` : `min-height:100px`"
  >
    <div class="header__block">
      <img :src="require(`@/assets/img/` + img)" :alt="img" v-if="img" />
      <t-logo v-else />
    </div>
  </header>
</template>

<script>
export default {
  name: "t-header",
  data() {
    return {
      img: `banner.png`,
      imgMinHeight: 200,
    };
  },
};
</script>

<style lang="scss" scoped>
.logo {
  justify-content: flex-start;
}
.block-empty {
  height: 200px;
}
</style>
