<template>
  <div
    class="block-flex"
    v-if="
      orderObj.products_ids.indexOf(product.id) == -1 &&
      (product.modifiers.length === 0 || product.modifiers_as_hint)
    "
  >
    <div class="price">{{ product.price }} ₽</div>
    <div
      class="btn-green add-to-cart"
      @click="
        $emit(
          'addToOrder',
          orderObj.id,
          product.id,
          product.modifiers,
          product.modifiers_as_hint
        )
      "
    >
      +
    </div>
  </div>
</template>

<script>
export default {
  name: "t-add-to-cart",
  props: ["orderObj", "product"],
  data() {
    return {
      orderId: localStorage.getItem("orderId"),
    };
  },
};
</script>
