<template>
    <div class="virtual-assistant-wrapper">
        <div class="virtual-assistant-header">
            <div
                class="virtual-assistant-header-text"
                :style="{
                    backgroundImage:
                        'url(' +
                        require('@/assets/img/svg/virtual-assistant-bg.svg') +
                        ')',
                }"
            >
                Не знаете что заказать?
                <span></span>
            </div>
            <div class="virtual-assistant-logo" @click="showAssistant()">
                <svg
                    width="45"
                    height="42"
                    viewBox="0 0 45 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.5119 20.1222C18.5902 20.1222 15.4128 23.4981 15.4128 27.6578C15.5617 32.3896 17.9291 37.1689 22.5119 41.9987C19.7217 42.0335 17.4169 41.3413 14.7428 40.3899C6.31565 37.3933 0.514893 28.5334 0.514893 18.5386V2.96808C7.13157 2.96808 12.2921 6.97925 12.2921 16.6516C12.2921 7.21631 16.2704 3.94163 22.5149 0C28.7564 3.93847 32.7377 7.21631 32.7377 16.6516C32.7377 6.97925 37.8982 2.96808 44.5149 2.96808V18.5386C44.5149 28.5302 38.7141 37.3933 30.287 40.3899C27.6129 41.3413 25.3081 42.0335 22.5179 41.9987C27.1007 37.1689 29.4681 32.3896 29.617 27.6578C29.617 23.4949 26.4396 20.1222 22.5179 20.1222"
                        fill="white"
                    />
                </svg>
            </div>
        </div>
        <div class="virtual-assistant-body" :class="active ? 'active' : ''">
            <div class="virtual-assistant-body-product">
                <p v-if="answer">{{ answer }}</p>
                <span v-else class="loading">
                    <span class="loading__item loading__item_1"></span>
                    <span class="loading__item loading__item_2"></span>
                    <span class="loading__item loading__item_3"></span>
                </span>
            </div>
            <button style="margin-bottom: 12px" @click="assistantAddToCart()">Добавь в корзину</button>
            <button @click="refreshRecommendations()">Посоветуй что-то еще</button>
            <div class="virtual-assistant-logo" @click="showAssistant()">
                <svg
                    width="45"
                    height="42"
                    viewBox="0 0 45 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.5119 20.1222C18.5902 20.1222 15.4128 23.4981 15.4128 27.6578C15.5617 32.3896 17.9291 37.1689 22.5119 41.9987C19.7217 42.0335 17.4169 41.3413 14.7428 40.3899C6.31565 37.3933 0.514893 28.5334 0.514893 18.5386V2.96808C7.13157 2.96808 12.2921 6.97925 12.2921 16.6516C12.2921 7.21631 16.2704 3.94163 22.5149 0C28.7564 3.93847 32.7377 7.21631 32.7377 16.6516C32.7377 6.97925 37.8982 2.96808 44.5149 2.96808V18.5386C44.5149 28.5302 38.7141 37.3933 30.287 40.3899C27.6129 41.3413 25.3081 42.0335 22.5179 41.9987C27.1007 37.1689 29.4681 32.3896 29.617 27.6578C29.617 23.4949 26.4396 20.1222 22.5179 20.1222"
                        fill="white"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
    data() {
        return {
            active: false,
            answer: null,
            product: null,
        };
    },

    props: {
        orderObj: {
            type: Object,
            default: null,
        },
    },

    methods: {
        showAssistant() {
            if (this.active) {
                this.active = false;
            } else {
                this.active = true;
                this.getRecommendations();
            }
        },

        getRecommendations() {
            axios
                .post(api + `recommended-assistant/`, {
                    order_id: this.orderObj.id,
                })
                .then((response) => {
                    console.log(response);
                    this.answer = response.data.answer
                    this.product = response.data.product
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        refreshRecommendations() {
            this.answer = null
            this.product = null
            axios
                .post(api + `recommended-assistant/`, {
                    order_id: this.orderObj.id,
                })
                .then((response) => {
                    console.log(response);
                    this.answer = response.data.answer
                    this.product = response.data.product
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        assistantAddToCart() {
            console.log(this.product);
            if(this.orderObj && this.product) {
                // let data = {
                //     order_id: this.orderObj.id,
                //     product_id: this.product.uid
                // }

                this.$emit('addToCart', this.orderObj.id, this.product.id, [], [], false); 
            }
        }
    },
};
</script>
<style scoped lang="sass">
.virtual-assistant-wrapper
    position: absolute
    right: 38px
    bottom: 128px
    z-index: 9999

.virtual-assistant
    &-header
        position: relative
        display: flex
        align-items: center
        gap: 6px
        &-text
            padding-right: 20px
            display: flex
            align-items: center
            justify-content: center
            font-size: 20px
            font-weight: 700
            line-height: 24px
            width: 310px
            height: 70px
            background-size: cover
            background-repeat: no-repeat
    &-logo
        display: flex
        align-items: center
        justify-content: center
        width: 80px
        height: 80px
        background: linear-gradient(319.24deg, #28735F 14.65%, #31D2A7 83.6%)
        border-radius: 1000px
        box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.15)
    &-body
        position: absolute
        bottom: 0
        padding: 32px
        display: none
        flex-direction: column
        align-items: flex-start
        background: #fff
        border-radius: 20px
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.15)
        &.active
            display: flex
        &-product
            padding: 22px 24px
            margin-bottom: 28px
            display: flex
            flex-direction: column
            gap: 18px
            background: #F5F5F5
            border-radius: 4px 20px 20px 20px
            & p
                font-size: 20px
                font-weight: 700
                line-height: 24px
            & span
                font-size: 16px
                font-weight: 400
                line-height: 22px
        & button
            padding: 14px 18px
            font-size: 18px
            font-weight: 700
            line-height: 24px
            background: #fff
            border: 1px solid #C6C6C6
            border-radius: 16px
        & .virtual-assistant-logo
            position: absolute
            bottom: 32px
            right: 32px

.loading
    display: flex
    align-items: center
    justify-content: center
    gap: 12px
    width: 284px
    &__item
        width: 12px
        height: 12px
        background: #000
        border-radius: 1000px
        animation: bounce 1.5s 0.5s linear infinite
        &_2
            animation-delay: 0.1s
        &_3
            animation-delay: 0.2s

@keyframes bounce
    0%
        transform: scale(1)
    25%
        transform: scale(0.6)
    50%
        transform: scale(1)
    75%
        transform: scale(1.4)
    100%
        transform: scale(1)
</style>
