<template>
  <div
    class="counter"
    v-if="
      orderObj.products_ids.indexOf(product.id) != -1 &&
      (product.modifiers.length === 0 || product.modifiers_as_hint)
    "
  >
    <button
      class="counter__minus"
      @click="
        $emit(
          'changeOrderItem',
          orderObj.id,
          getOrderItem(product.id),
          'decrease'
        )
      "
    >
      -
    </button>
    <span class="counter__price">{{ showPrice(product.id) }} ₽</span>
    <button
      class="counter__plus"
      @click="
        $emit(
          'changeOrderItem',
          orderObj.id,
          getOrderItem(product.id),
          'increase'
        )
      "
    >
      +
    </button>
  </div>
</template>

<script>
export default {
  name: "t-counter-price",
  props: ["orderObj", "product", "getOrderItem", "showPrice"],
  data() {
    return {
      orderId: localStorage.getItem("orderId"),
    };
  },
};
</script>
