<template>
  <button class="btn btn-arrow flex-center">
    <img
      src="@/assets/img/svg/btn-arrow.svg"
      alt="arrow"
      width="13.47"
      height="30.32"
    />
  </button>
</template>

<script>
export default {
  name: "t-btn-arrow",
};
</script>

<style lang="scss">
.btn-arrow {
  width: 63.75px;
  height: 64px;
  background: none;
  &:hover,
  &:focus {
    img {
      filter: invert(0.5);
      transition: 0.2s ease 0.2s;
    }
  }
}
</style>
