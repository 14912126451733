<template>
  <div class="payment" v-if="paid">
    <div class="payment__container _container">
      <div class="payment__body">
        <h1 class="payment__title">Оплата прошла успешно</h1>
      </div>
      <h2 class="payment__subtitle">
        <span class="order-number" v-if="orderObj.order_number"
          >Номер вашего заказа<br /><font v-if="orderObj.name">{{
            orderObj.name
          }}</font></span
        >
      </h2>
    </div>
  </div>
  <div class="payment" v-if="!paid">
    <div class="payment__before-pay">
      <div class="payment__scan-qr" v-if="scanQR">
        <h2 class="payment__title">Отсканируйте QR-код</h2>
        <h3 class="payment__subtitle">
          В мобильном приложении<br />
          вашего банка
        </h3>
        <div class="payment__qr-spb">
          <div class="payment__spb-logo">
            <img src="@/assets/img/fast_spb.png" alt="spb" />
          </div>
          <div class="payment__qr-code" v-html="qrCode"></div>
        </div>
        <div class="payment__after-pay">
          После оплаты заказа подождите<br />
          несколько секунд, окно закроется<br />
          автоматически
        </div>
        <button class="payment__btn-cancel" @click="cancelQR">Отменить</button>
      </div>
      <div class="payment__our-order" v-else>
        <h2 class="payment__title">Ваш заказ</h2>
        <ul class="payment__total">
          <li><span>Списано Т-коинов</span><span>-</span></li>
          <li>
            <span>Начислим Т-коинов</span
            ><span>{{ orderObj.full_price / 20 }}</span>
          </li>
          <li>
            <span>Сумма</span><span>{{ orderObj.full_price }} ₽</span>
          </li>
        </ul>
        <h3 class="payment__method-title">Выберите<br />способ оплаты</h3>
        <div class="payment__method-pays">
          <button class="payment__method-pay" @click="$router.push(`/payment`)">
            <img src="@/assets/img/svg/card.svg" alt="card" width="45.83" />
            <span>Картой</span>
          </button>
          <button class="payment__method-pay" @click="payQR" v-if="terminalKey">
            <img src="@/assets/img/spb_icon.png" alt="spb" width="52" />
            <span>По QR-коду через СБП</span>
          </button>
          <button class="payment__back-arrow" @click="$router.push('/cart')">
            <img src="@/assets/img/svg/arrow-back.svg" alt="back" width="20" />
            <span>Назад</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-payment",
  data() {
    return {
      waitingPay: false,
      scanQR: false,
      orderObj: null,
      qrCode: "",
      orderId: localStorage.getItem("orderId"),
      paid: false,
      terminalKey: "",
      interval: "",
    };
  },
  methods: {
    payCard() {
      this.waitingPay = true;
    },
    payQR() {
      this.scanQR = true;
    },
    cancelQR() {
      this.scanQR = false;
    },

    getOrderStatus() {
      let self = this;
      axios.get(api + `orders/` + self.orderId + "/").then(function (response) {
        self.orderObj = response.data;
        if (self.orderObj.order_status == "Оплачен") {
          self.$router.push("/finish/");
        }
      });
    },
  },
  mounted() {
    let self = this;

    axios.get(api + `orders/` + self.orderId + "/").then(function (response) {
      self.orderObj = response.data;
    });

    axios.get(api).then((response) => {
      self.terminalKey = response.data.tinkoff_terminal_key;
    });

    axios
      .post(api + "create-qr/", { order_id: self.orderId })
      .then(function (response) {
        self.qrCode = response.data.sbp_qr;
      });

    this.interval = setInterval(() => {
      this.getOrderStatus();
    }, 3000);
  },

  unmounted() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/payment.scss";

.payment__qr-code svg {
  width: 370px !important;
  height: 370px !important;
  @media (max-width: $ms3) {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
