import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Top from "./components/Top";
import Logo from "./components/Logo";
import Ornament from "./components/Ornament";
import Header from "./components/Header";
import OrdersHall from "./components/OrdersHall";
import OrderHall from "./components/OrderHall";
import TotalSum from "./components/TotalSum";
import CounterPrice from "./components/CounterPrice";
import Changed from "./components/Changed";
import SelectCombo from "./components/SelectCombo";
import ShowPopup from "./components/ShowPopup";
import BlockOrder from "./components/BlockOrder";
import Sidebar from "./components/Sidebar";
import MenuItems from "./components/MenuItems";
import BtnArrow from "./components/BtnArrow";
import AddCancel from "./components/AddCancel";
import Keyboard from "./components/Keyboard";
import FooterOrder from "./components/FooterOrder";
import Slider from "./components/Slider";
import ListProducts from "./components/ListProducts";
import AddToCart from "./components/AddToCart";
import BlockEmpty from "./components/BlockEmpty";
import BackToPage from "./components/BackToPage";

import { languages } from "./i18n";
import { defaultLocale } from "./i18n";
import { createI18n, useI18n } from "vue-i18n";

const localeStorageLang = localStorage.getItem("lang");

const messages = Object.assign(languages);
const i18n = createI18n({
  legacy: false,
  locale: localeStorageLang || defaultLocale,
  fallbackLocale: "ru",
  messages,
});

const app = createApp(App, {
  setup() {
    const { t } = useI18n();
    return { t };
  },
});

import VueTheMask from "vue-the-mask";

app.component("t-top", Top);
app.component("t-logo", Logo);
app.component("t-ornament", Ornament);
app.component("t-header", Header);
app.component("t-orders-hall", OrdersHall);
app.component("t-order-hall", OrderHall);
app.component("t-total-sum", TotalSum);
app.component("t-counter-price", CounterPrice);
app.component("t-changed", Changed);
app.component("t-select-combo", SelectCombo);
app.component("t-show-popup", ShowPopup);
app.component("t-block-order", BlockOrder);
app.component("t-sidebar", Sidebar);
app.component("t-menu-items", MenuItems);
app.component("t-btn-arrow", BtnArrow);
app.component("t-add-cancel", AddCancel);
app.component("t-keyboard", Keyboard);
app.component("t-footer-order", FooterOrder);
app.component("t-slider", Slider);
app.component("t-list-products", ListProducts);
app.component("t-add-to-cart", AddToCart);
app.component("t-block-empty", BlockEmpty);
app.component("t-back-to-page", BackToPage);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueTheMask);
app.mount("#app");

import * as Sentry from "@sentry/vue";
Sentry.init({
  app,
  dsn: "https://6e410c548dd9d09f995fddf28e61149f@o4504809008070656.ingest.sentry.io/4505834144727040",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
