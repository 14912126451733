<template>
  <div class="auth" v-if="isAuth">
    <div class="auth__container" @click.stop>
      <div class="auth__img-a img-top-l" v-if="authSign">
        <img
          :src="require(`@/assets/img/coins/` + img_top_l)"
          :alt="img_top_l"
        />
      </div>
      <div class="auth__img-a img-top-r" v-if="authSign">
        <img
          :src="require(`@/assets/img/coins/` + img_top_r)"
          :alt="img_top_r"
        />
      </div>
      <div class="auth__img-a img-bottom-r" v-if="authSign">
        <img
          :src="require(`@/assets/img/coins/` + img_bottom_r)"
          :alt="img_bottom_r"
        />
      </div>
      <div class="auth__img-a img-bottom-l" v-if="authSign">
        <img
          :src="require(`@/assets/img/coins/` + img_bottom_l)"
          :alt="img_bottom_l"
        />
      </div>
      <div class="auth__body">
        <div class="auth__close" @click="$emit('closeAuth')">
          <img :src="require(`@/assets/img/` + close)" :alt="close" />
        </div>
        <div class="auth-sign">
          <h2 class="auth-sign__title">
            {{ authTitle }}
          </h2>
          <div class="auth-sign__bonuses" v-if="authSign">
            <span>{{ Math.round((orderObj.full_price / 100) * 7) }}</span>
            <svg
              width="89"
              height="83"
              viewBox="0 0 89 83"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M44.494 39.7654C36.5613 39.7654 30.1345 46.4366 30.1345 54.6571C30.4356 64.0081 35.2242 73.4528 44.494 82.9975C38.8502 83.0662 34.1881 81.6982 28.7792 79.818C11.7334 73.8963 0 56.3873 0 36.6358V5.86548C13.3837 5.86548 23.8221 13.7923 23.8221 32.9067C23.8221 14.2608 31.8692 7.78941 44.5 0C57.1248 7.78316 65.1779 14.2608 65.1779 32.9067C65.1779 13.7923 75.6163 5.86548 89 5.86548V36.6358C89 56.3811 77.2667 73.8963 60.2208 79.818C54.8119 81.6982 50.1498 83.0662 44.506 82.9975C53.7759 73.4528 58.5644 64.0081 58.8655 54.6571C58.8655 46.4304 52.4387 39.7654 44.506 39.7654"
                fill="white"
              />
            </svg>
          </div>
          <h4 class="auth-sign__subtitle" v-if="authSign">{{ subtitle }}</h4>
        </div>
        <div class="phone-input" v-if="inputNumbers">
          <div
            class="phone-input__phone"
            :class="{
              notvalid: phoneValidation.phone,
              valid: phoneValidation.phone === false,
            }"
            :style="phone.length === 0 ? 'color:#bebebe !important' : ''"
            v-if="phoneInput"
          >
            <div class="tel-nums">
              <div class="seven">+7</div>
              <div class="tel-input">
                (
                <div>
                  <span v-if="phone[0]">
                    {{ phone[0] }}
                  </span>
                  <span v-else>_</span>
                </div>
                <div>
                  <span v-if="phone[1]">
                    {{ phone[1] }}
                  </span>
                  <span v-else>_</span>
                </div>
                <div>
                  <span v-if="phone[2]">
                    {{ phone[2] }}
                  </span>
                  <span v-else>_</span>
                </div>
                )&nbsp;
                <div>
                  <span v-if="phone[3]">
                    {{ phone[3] }}
                  </span>
                  <span v-else>_</span>
                </div>
                <div>
                  <span v-if="phone[4]">
                    {{ phone[4] }}
                  </span>
                  <span v-else>_</span>
                </div>
                <div>
                  <span v-if="phone[5]">
                    {{ phone[5] }}
                  </span>
                  <span v-else>_</span>
                </div>
                -
                <div>
                  <span v-if="phone[6]">
                    {{ phone[6] }}
                  </span>
                  <span v-else>_</span>
                </div>
                <div>
                  <span v-if="phone[7]">
                    {{ phone[7] }}
                  </span>
                  <span v-else>_</span>
                </div>
                -
                <div>
                  <span v-if="phone[8]">
                    {{ phone[8] }}
                  </span>
                  <span v-else>_</span>
                </div>
                <div>
                  <span v-if="phone[9]">
                    {{ phone[9] }}
                  </span>
                  <span v-else>_</span>
                </div>
              </div>
            </div>
            <input
              type="hidden"
              v-mask="'##########'"
              name="phone"
              placeholder="(___) ___-__-__"
              id="phone"
              v-model.trim="phone"
              @input="inputPhone"
            />
          </div>
          <div v-if="nameInput">
            <div class="subtitle">
              <span>Пожалуйста, введите свое имя для</span>
              <span>отображения в профиле</span>
            </div>
            <input
              type="text"
              placeholder="Имя"
              v-model="name"
              class="name-input"
            />
          </div>
          <div
            class="phone-input__keys phone-rows"
            v-if="phoneInput"
            @click="inputPhone"
          >
            <div class="phone-row">
              <button class="phone-input__key key">1</button>
              <button class="phone-input__key key">2</button>
              <button class="phone-input__key key">3</button>
            </div>
            <div class="phone-row">
              <button class="phone-input__key key">4</button>
              <button class="phone-input__key key">5</button>
              <button class="phone-input__key key">6</button>
            </div>
            <div class="phone-row">
              <button class="phone-input__key key">7</button>
              <button class="phone-input__key key">8</button>
              <button class="phone-input__key key">9</button>
            </div>
            <div class="phone-row">
              <button class="btn btn-green btn-clear key">
                <img
                  src="@/assets/img/svg/delete.svg"
                  alt="delete"
                  class="delete"
                />
              </button>
              <button class="phone-input__key key">0</button>
              <button class="btn btn-green key">
                <img
                  src="@/assets/img/svg/clear.svg"
                  alt="clear"
                  class="clear"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="auth-success" v-if="authSuccess">
          <h2 class="auth-success__title">{{ success }}</h2>
          <div class="auth-success__phone">
            <p class="auth-success__our-tel">Ваш телефон</p>
            <div class="auth-success__input-tel">+7 {{ phone }}</div>
          </div>
        </div>
      </div>
      <div class="auth__bottom">
        <div class="auth__buttons" v-if="authSign">
          <button class="btn btn-bgn" @click="$emit('closeAuth')">
            Пропустить
          </button>
          <button class="btn btn-green" @click="showPhoneInput()">Войти</button>
        </div>
        <div class="auth__buttons" v-if="inputNumbers">
          <template v-if="phoneInput"
            ><button class="btn btn-bgn" @click="cancelPhoneInput()">
              Назад
            </button>
            <button class="btn btn-green btnAddPhone" @click="validPhone()">
              Продолжить
            </button></template
          >
          <template v-if="nameInput"
            ><button class="btn btn-bgn" @click="cancelValidPhone()">
              Назад</button
            ><button class="btn btn-green btnAddPhone" @click="validName()">
              Продолжить
            </button></template
          >
          <template v-if="codeInput"
            ><button class="btn btn-bgn" @click="cancelValidName()">
              Назад</button
            ><button class="btn btn-green btnAddPhone" @click="validCode()">
              Продолжить
            </button></template
          >
        </div>
        <div class="auth__buttons" v-if="finishAuth">
          <button class="btn btn-green" @click="$emit('closeAuth')">
            Продолжить
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="keyboard-block popupInputName" v-if="isInputName">
    <div class="keyboard">
      <div class="keyboard__block" @click.stop>
        <div class="keyboard__container _container">
          <div class="keyboard__body">
            <div class="keyboard__keys" @click="inputName">
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="(number, index) in 9"
                  :key="index"
                >
                  {{ number }}
                </button>
                <button class="keyboard__key key">0</button>
                <button class="btn btn-lightgreen btn-long key">
                  {{ $t("cart.btn_clear") }}
                </button>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in letters1"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <button class="btn btn-lightgreen btn-long2 key">
                  <img
                    src="@/assets/img/svg/clear.svg"
                    alt="clear"
                    class="clear"
                  />
                </button>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in letters2"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <template v-if="name.length >= 2">
                  <button class="btn btn-lightgreen btn-long2 key">
                    <img
                      src="@/assets/img/svg/space.svg"
                      alt="space"
                      class="space"
                    />
                  </button>
                </template>
                <template v-else>
                  <button class="btn btn-lightgreen btn-long2 key" @click.stop>
                    <img
                      src="@/assets/img/svg/space.svg"
                      alt="space"
                      class="space"
                    />
                  </button>
                </template>
              </div>
              <div class="keyboard__row">
                <button
                  class="keyboard__key key"
                  v-for="letter in letters3"
                  :key="letter.id"
                >
                  {{ letter.char }}
                </button>
                <button class="btn btn-lightgreen btn-long key">ABC</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
  name: "t-popup-auth",
  data() {
    return {
      finishAuth: false,
      isInputName: false,
      nameInput: false,
      isAuth: true,
      authSign: true,
      phoneInput: false,
      authSuccess: false,
      codeInput: false,
      inputNumbers: false,
      success: "Неверный промокод! Попробуйте ещё раз!",
      phoneValidation: {
        phone: null,
      },
      phone: "",
      promocodeInput: "",
      authTitle: "Вы могли бы получить",
      codeValidation: {
        code: null,
      },
      code: "",
      promocodeValidation: {
        promocode: null,
      },
      letters: [
        { id: 1, char: `й` },
        { id: 2, char: `ц` },
        { id: 3, char: `у` },
        { id: 4, char: `к` },
        { id: 5, char: `е` },
        { id: 6, char: `н` },
        { id: 7, char: `г` },
        { id: 8, char: `ш` },
        { id: 9, char: `щ` },
        { id: 10, char: `з` },
        { id: 11, char: `х` },
        { id: 12, char: `ъ` },
        { id: 13, char: `ё` },
        { id: 14, char: `ф` },
        { id: 15, char: `ы` },
        { id: 16, char: `в` },
        { id: 17, char: `а` },
        { id: 18, char: `п` },
        { id: 19, char: `р` },
        { id: 20, char: `о` },
        { id: 21, char: `л` },
        { id: 22, char: `д` },
        { id: 23, char: `ж` },
        { id: 24, char: `э` },
        { id: 25, char: `я` },
        { id: 26, char: `ч` },
        { id: 27, char: `с` },
        { id: 28, char: `м` },
        { id: 29, char: `и` },
        { id: 31, char: `т` },
        { id: 32, char: `ь` },
        { id: 33, char: `б` },
        { id: 34, char: `ю` },
        { id: 35, char: `_` },
      ],
      close: "close.png",
      img: `t-coins.png`,
      name: "",
      letters1: [
        { id: 1, char: `й` },
        { id: 2, char: `ц` },
        { id: 3, char: `у` },
        { id: 4, char: `к` },
        { id: 5, char: `е` },
        { id: 6, char: `н` },
        { id: 7, char: `г` },
        { id: 8, char: `ш` },
        { id: 9, char: `щ` },
        { id: 10, char: `з` },
        { id: 11, char: `х` },
        { id: 12, char: `ъ` },
      ],
      letters2: [
        { id: 1, char: `ф` },
        { id: 2, char: `ы` },
        { id: 3, char: `в` },
        { id: 4, char: `а` },
        { id: 5, char: `п` },
        { id: 6, char: `р` },
        { id: 7, char: `о` },
        { id: 8, char: `л` },
        { id: 9, char: `д` },
        { id: 10, char: `ж` },
        { id: 11, char: `э` },
      ],
      letters3: [
        { id: 1, char: `я` },
        { id: 2, char: `ч` },
        { id: 3, char: `с` },
        { id: 4, char: `м` },
        { id: 5, char: `и` },
        { id: 6, char: `т` },
        { id: 7, char: `ь` },
        { id: 8, char: `б` },
        { id: 9, char: `ю` },
        { id: 10, char: `_` },
      ],
      token: localStorage.getItem("token"),
      subtitle: `Войдите в профиль,\n чтобы копить Т-коины`,
      img_top_l: "top-l.png",
      img_top_r: "top-r.png",
      img_bottom_l: "bottom-l.png",
      img_bottom_r: "bottom-r.png",
    };
  },
  props: ["orderObj"],
  methods: {
    showPhoneInput() {
      this.authSign = false;
      this.inputNumbers = true;
      this.phoneInput = true;
      this.authTitle = "Введите номер телефона";
    },
    cancelPhoneInput() {
      this.authSign = true;
      this.inputNumbers = false;
      this.phoneInput = false;
      this.authTitle = "Вы могли бы получить";
    },
    cancelClose() {
      this.isPopupKeyboad = false;
      this.message = "";
    },
    inputPhone() {
      if (
        event.target.className !== "keyboard__keys" &&
        event.target.className !== "phone-row"
      ) {
        let number = event.target.innerHTML || event.target.className;
        if (number === "clear")
          this.phone = this.phone.substr(0, this.phone.length - 1);
        else if (number === "delete") this.phone = "";
        else {
          this.phone.length > 9
            ? (this.phone = this.phone.substr(0, this.phone.length))
            : (this.phone += number);
        }
      }
      this.phoneValidation.phone = !/^[\d]{10}$/.test(this.phone)
        ? true
        : false;
    },
    inputName() {
      if (
        event.target.className !== "keyboard__keys" &&
        event.target.className !== "keyboard__row"
      ) {
        let key = event.target.innerHTML || event.target.className;
        if (key === "clear")
          this.name = this.name.substr(0, this.name.length - 1);
        else if (key === "Очистить") this.name = "";
        else if (key === "space") this.name += " ";
        else this.name += key.toUpperCase();
      }
    },
    addPhone() {
      axios
        .post(api + `phone-add/`, {
          order_id: this.orderObj.id,
          phone: this.phone,
          name: this.name,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    successValidPhone() {
      this.phoneInput = false;
      this.nameInput = true;
      this.authTitle = "Как вас зовут?";
      this.isInputName = true;
    },
    cancelValidPhone() {
      this.phoneInput = true;
      this.nameInput = false;
      this.authTitle = "Введите номер телефона";
      this.isInputName = false;
    },
    validPhone() {
      if (!this.phoneValidation.phone && this.phone.length > 0) {
        let self = this;
        axios
          .post(api + "iiko-name-by-phone/", { phone: "7" + this.phone })
          .then(function (response) {
            if (response.data.name) {
              self.name = response.data.name;
            }
            self.successValidPhone();
          });
      } else {
        this.cancelValidPhone();
      }
    },
    successValidName() {
      this.addPhone();
      this.nameInput = false;
      this.authTitle = "Вы успешно авторизованы!";
      this.isInputName = false;
      this.finishAuth = true;
    },
    cancelValidName() {
      this.nameInput = true;
      this.authTitle = "Как вас зовут?";
      this.isInputName = true;
      this.finishAuth = false;
    },
    validName() {
      this.name.length >= 2 ? this.successValidName() : this.cancelValidName();
    },
  },
  mounted() {
    //this.token ? (this.isAuth = false) : (this.isAuth = true);
  },
};
</script>
