<template>
  <footer class="footer" @click="this.$router.push('/cart/')">
    <div class="footer__body">
      <div class="footer__header">
        <div class="footer__container _container">
          <div class="footer__info">
            <h2 class="footer__title">
              <img
                :src="require(`@/assets/img/svg/cart.svg`)"
                alt="cart"
                width="48"
              />
              <p>{{ totalCountCart }} товаров в корзине</p>
            </h2>
            <div class="total-sum__price">
              <span v-if="orderObj.discount_amount <= 0">{{
                orderObj.full_price
              }}</span>
              <span v-if="orderObj.discount_amount > 0"
                ><span style="text-decoration: line-through">{{
                  orderObj.products_price
                }}</span>
                {{ orderObj.full_price }}</span
              >
              ₽
              <img
                :src="require(`@/assets/img/svg/arrow-next.svg`)"
                alt="cart"
                width="13.47"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "t-orders-hall",
  props: ["orderObj", "totalCountCart"],
  data() {
    return {
      isShowOrder: false,
      isHideOrder: true,
      arrow: `arrow-up.svg`,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.status {
  padding: 46px 45px 26px;
  @media (max-width: $ms4) {
    padding: 20px 20px 0 20px;
  }
}
.orders {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -16px;
  padding: 0px 0px 24px 0px;
  @media (max-width: $ms2) {
    justify-content: center;
  }
  @media (max-width: $ms3) {
    margin: 0 auto;
    padding: 0px 0px 24px 0px;
  }
}
.block-counter {
  display: flex;
  align-items: center;
  .price {
    margin-right: 17px;
    font-weight: 700;
    @include adaptive-font(25, 14, 0);
    @include adaptive-value("line-height", 26, 15, 0);
  }
}
.footer__orders {
  .counter {
    width: 180px;
    box-shadow: none;
    &__counts {
      font-weight: 700;
      @include adaptive-font(20, 14, 0);
      @include adaptive-value("line-height", 21, 15, 0);
    }
  }
}
</style>
